<template>
  <b-container>
    <form-Data :title="title" :type="type" :idKey="id" />
  </b-container>
</template>
<script>
import formData from "./form/createdorUpdate.vue";
export default {
  components: {
    formData,
  },
   async mounted() {
    this.$store.commit("SET_BREADCRUMB", this.items);
  },
  data() {
    return {
      id: this.$route.params.id,
      title: "Detalles De Usuarios",
      type: 3,
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          html: "Listado Usuarios",
          to: "/users/list",
        },
        {
          text: "Detalles de Usuarios",
          active: true,
        },
      ],
    };
  },
};
</script>
